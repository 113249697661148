// Course content area styles
.learner-section__title__container {
  margin-bottom: 10px;
  h3 {
    font-family: Inter !important;
    margin: 0;
    .learner-section__title {
      color: #333333;
      font-size: 12px;
      display: block;
      text-transform: uppercase;
      .learner-section__title__slash {
        display: none;
      }
    }
    .learner-lesson__title {
      color: #656565;
      font-size: 24px;
      font-weight: bold;
      margin-left: -1px;
      padding-top: 15px;
      padding-bottom: 5px;
    }
  }
}

.course__container {
  .learner__container {
    .learner__content {
      .layout-panel {
        background-color: transparent;
        padding-top: 0;
        .learner-section__progress {
          position: absolute;
          top: 80px;
          z-index: 2;
          padding-left: 30px;
          padding-right: 30px;
          .section-progress-bar {
            div {
              background-color: transparent;
            }
            span {
              background: linear-gradient(90deg, #007be1 0.95%, #17a1d5 50.63%, #2ac9ca 98.28%) !important;
            }
          }
        }

        .directional__nav {
          position: absolute;
          top: 55px;
          z-index: 9;
          &.bottom-nav {
            bottom: 0;
            top: auto;
            // position: relative;
            margin-bottom: 20px;

            &.admin-view {
              bottom: 70px;
            }
          }
          .directional__nav__button {
            background-color: transparent;
            margin-right: 0;
            margin-left: 0;
            i {
              color: #b6b6b6 !important;
            }
            i.icon-navigateleft::before {
              content: '\E630';
            }
            i.icon-navigateright::before {
              content: '\E62E';
            }
          }
          .directional__nav__label {
            color: #b6b6b6 !important;
            display: block;
            background-color: #fff;
            padding: 5px;
            margin-top: -5px;
          }
        }

        .behind-paywall {
          .directional__nav,
          .learner-section__progress {
            position: relative;
            top: 0;
            &.bottom-nav {
              display: none;
            }
          }
        }

        .ember-view.topic__container {
          @include card;
          padding-top: 50px;
          padding-bottom: 60px;
        }
      }
    }

    .topic__content {
      margin-bottom: 0.5em;
    }
  }
}

.audio__player {
  padding-bottom: 20px;
}
.btn.audio-player__play-button {
  background-color: #0099cc;
}
.section-progress-bar span {
  background: #0099cc !important;
}
