.dash-hero {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  .hero__caption {
    background: transparent;
    .hero__title {
      color: #fff;
      font-style: normal;
      font-weight: 900;
      font-size: 41px;
      line-height: 50px;

      /* White */
      color: #ffffff;
      mix-blend-mode: normal;
      margin-bottom: 0;
    }
    .h4 {
      color: #fff;
    }
  }

  span.h4 {
    color: #fff;
    font-style: normal;
    font-weight: 900;
    font-size: 41px;
    line-height: 50px;

    /* White */

    color: #ffffff;
    mix-blend-mode: normal;
  }
}

.hero {
  height: 400px;
  background: black;
  overflow: hidden;
  background: #c33764;
  background: -webkit-linear-gradient(94.99deg, rgba(48, 207, 208, 0.8) 0%, rgba(51, 8, 103, 0.8) 100%),
    url(https://photoshop-kopona.com/uploads/posts/2018-03/1521289405_17-8.jpg);
  background: linear-gradient(94.99deg, rgba(48, 207, 208, 0.8) 0%, rgba(51, 8, 103, 0.8) 100%),
    url(https://photoshop-kopona.com/uploads/posts/2018-03/1521289405_17-8.jpg);
  background-size: cover;

  picture,
  img {
    display: block;
  }

  .hero__caption {
    bottom: 5em;
    max-width: 500px;
    margin-left: 120px;
  }
}

// dashboard stats container related
.widget--dashboard-stats {
  position: absolute;
  z-index: 2;
  width: 1000px;
  right: 0;
  top: 5em;
  .dashboard-views__container {
    display: none;
  }
}

.dashboard-stat--available {
  display: none !important;
}

.dashboard-stats__container {
  box-shadow: none;
  top: 5em;
  position: absolute;
}

// dashboard stat item related
@media only screen and (min-width: 48.063em) {
  .dashboard-stats > li {
    width: 18%;
  }
}

.dashboard-stats li {
  width: 200px;
}
.dashboard-stat {
  border: none;
  border-radius: 0.85rem !important;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
}

.dashboard-stat__value {
  color: #fff !important;
  font-weight: 300;
  font-size: 73px;
  line-height: 88px;
  text-transform: uppercase;
}

.dashboard-stat__label {
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 11.4062px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 25px;
  height: 70px;
  i {
    font-size: 28px;
  }
}

.dashboard-stat__icon {
  width: 100%;
  margin-bottom: 11px;
  align-items: center;
  display: flex;
  justify-content: center;
  &::before {
    display: none;
  }
  &::after {
    background-size: cover;
    height: 29px;
    width: 29px;
    display: inline-block;
  }
  &[aria-label='Course Started'] {
    &::after {
      content: ' ';
      background: url('/assets/icons/book-open.svg') no-repeat;
    }
  }
  &[aria-label='Course Completed'] {
    &::after {
      content: ' ';
      background: url('/assets/icons/check-circle.svg') no-repeat;
    }
  }
  &[aria-label='Certificates of Completion'] {
    &::after {
      content: ' ';
      background: url('/assets/icons/flag.svg') no-repeat;
    }
  }
}

.dashboard-stat--available .dashboard-stat__value,
.dashboard-stat--started .dashboard-stat__value,
.dashboard-stat--completed .dashboard-stat__value,
.dashboard-stat--collaborations .dashboard-stat__value {
  color: #0099cc;
}

.dashboard-views__line__bar {
  background-color: #0099cc;
}

.dashboard-views__header {
  font-size: 14px;
}

.dashboard-stat--collaborations {
  display: none !important;
}

@media only screen and (max-width: 1024px) {
  .dash-hero {
    .hero__caption {
      position: relative !important;
      bottom: 0 !important;
      max-width: 100% !important;
      margin-left: 15px !important;
    }
  }

  .learn.dashboard {
    .widget.widget--dashboard-stats {
      width: 100% !important;

      .dashboard-stats__container {
        top: 9em;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .dash-hero {
    .hero__caption {
      h2,
      span {
        font-size: 28px !important;
      }
    }
  }

  .learn.dashboard {
    .widget.widget--dashboard-stats {
      .dashboard-stats__container {
        top: 10em;
        width: 100%;
        padding-right: 26px;
        .dashboard-stats {
          display: flex !important;
          justify-content: center;
          li {
            width: 150px;
          }
        }
        .dashboard-stat__value.dashboard-stat__value--huge {
          font-size: 40px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 585px) {
  .dash-hero {
    .hero__caption {
      margin-left: 0 !important;

      h2,
      span {
        font-size: 26px !important;
        line-height: 36px;
      }
    }
  }

  .learn.dashboard {
    .widget.widget--dashboard-stats {
      right: auto;
      padding: 0 !important;
      max-width: 90%;
      .dashboard-stats__container {
        top: 14em;
        margin-left: 10px;
        padding-right: 42px;
        .dashboard-stats {
          margin: 0;
          li {
            width: auto;
            flex: 1;
          }

          .dashboard-stat__label {
            font-size: 8px;
            margin-bottom: 10px;
            height: 50px;
          }
          .dashboard-stat__value.dashboard-stat__value--huge {
            font-size: 25px !important;
          }
        }
      }
    }
  }
}

.banner-ad-slider {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
