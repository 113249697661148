/*Dashboard*/
@import './header.scss';
@import './hero-section.scss';
@import './main.scss';
@import './catalog.scss';
@import './footer.scss';
@import './banner-ad.scss';

// global in the dashboard page
.learn.dashboard .widget,
.learn.transcript .widget {
  padding: 1rem 10rem;
}
