.course__container {
  .learner__sidebar__search {
    .small-8,
    .small-4 {
      width: 100%;
    }
    input {
      @include input-style;
      border-radius: 30px;
      height: 45px !important;
      padding-left: 45px;
      margin-bottom: 30px;
    }
    .learner__sidebar__search-icon {
      margin-top: 10px;
      margin-left: 15px;
    }
    .icon-search {
      font-size: 16px;
    }

    .learner__container__toggle--small {
      .btn {
        width: auto;
        display: inline-block;
      }
    }
  }

  .learner__sidebar__expandables {
    .expandable-sidebar {
      margin-bottom: 10px;
      .expandable-sidebar__content {
        border: 1px solid #e9e9e9;
        border-top: 0;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }

  .learner__sidebar {
    .layout-panel {
      background: none;
    }
    .expandable-sidebar {
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
    }

    .expandable-sidebar__content {
      background: #fff;
    }
  }
}

// course side bar related styles
button.syllabus__lesson--expander {
  .expander-triangle__closed-right {
    border-left-color: #0099cc !important;
  }
  .expander-triangle__closed-down {
    border-top-color: #0099cc !important;
  }
}

.expandable-sidebar {
  .icon-navigatedown,
  .icon-navigateright {
    color: #fff !important;
  }
}

button .expandable-sidebar__toggle {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}
.expandable-sidebar__toggle {
  background-color: #0099cc !important;
  padding: 22px 10px;

  .syllabus__lesson--title-text {
    font-size: 16px;
  }
}

.syllabus__topic--active {
  background: #0099cc !important;
  color: #fff !important;
  .syllabus__topic--non-expandable-title-text {
    color: #fff !important;
  }
}

.icon-circle {
  &.icon-circle--full {
    border-color: #0099cc;
    span {
      background: #0099cc;
    }
  }
}
.course-progress__overall .highlight {
  color: #0099cc;
}
.course-progress__overall__bar .progress .meter {
  background: linear-gradient(90deg, #0099cc, #0077bb);
}

.progress.colorized .meter {
  background: linear-gradient(90deg, #0185e5 0.95%, #a1d683 50.63%, #f2c94c 98.28%);
}

.layout-panel {
  .icon-navigateleft,
  .icon-navigateright {
    color: #fff !important;
  }
}

.learner__container--closed {
  .learner__container__toggle {
    button {
      margin-left: 16px;
    }
  }
}

.learner__container__toggle {
  margin-bottom: 5px;
  button {
    font-size: 12px;
    background: #0099cc;
    border-radius: 5px !important;
    border: 0;
    margin-bottom: 5px;
  }
}

.completed__indicator .icon-check {
  color: #0099cc;
}
