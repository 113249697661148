// this will affect all custom built pages
.header.header--microsite {
  .header__inner {
    padding-left: 1rem;
    padding-bottom: 5px;
  }
  .nav--top {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-right: 20px;
    ul {
      float: none;
      display: flex;
      flex-direction: row;
      li {
        float: none;
        & > a {
          &:focus {
            background-color: transparent;
          }
          & > span {
            color: #333333;
            font-size: 12px;
            text-transform: none;
          }
          &:hover {
            & > span {
              color: #0099cc;
            }
          }
        }

        &.cart-link-container {
          display: none;
        }
      }
    }
  }
}
