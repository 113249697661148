@import './global.scss';
@import './dashboard/index.scss';
// NOT FOR MVP
// @import './course-enroll/index.scss';
@import './course-detail/index.scss';
@import './checkout/index.scss';
@import './redemption-page/index.scss';
@import './account-page/index.scss';
@import './modal-cart/index.scss';
@import './custom-pages/index.scss';
@import './learning-path/index.scss';

// Unknown location styles
.footertext {
  float: right;
  padding-right: 50px;
}

.medium-block-grid-3 > li,
.medium-block-grid-4 > li,
.medium-block-grid-5 > li {
  width: 140px;
}

.nav--top {
  display: none;
}

.home__content {
  min-height: 83.6vh;
}

// END - Unknown location styles
