.header--dashboard {
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0);

  // repositioning the sub license switcher
  .header__inner {
    & > .row > .column:last-of-type {
      div > .row.collapse {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }
  }

  .form.form--admin {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10px;

    .field__input-wrapper--select {
      position: relative;
      max-width: 280px;
      width: 100%;
      &::after {
        content: 'Dashboard: ';
        position: absolute;
        left: -100px;
        background: transparent;
      }
      &::before {
        right: 7px;
      }
    }

    select {
      background-color: transparent;
      margin-bottom: 0 !important;
      font-family: Nunito, sans-serif;
      padding: 5px 25px 5px 5px;
      color: #333;
      height: 30px;
      box-shadow: none;
    }
  }
}

// styles for the profile dropdown
.dashboard-header-dropdown__link {
  width: 200px;
  float: right;

  & > .row {
    display: flex;
    align-items: center;
    .columns {
      float: none !important;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &.small-10 {
        width: 70%;
      }
      &.small-2 {
        width: 30%;
      }
    }
  }

  .dashboard-header__name {
    margin: 0 10px 0 0;
    padding: 0;
    color: #333333;
    font-size: 0.75rem;
    max-width: 110px;
    &:hover {
      color: #0099cc;
    }
  }
  .dashboard-header__avatar__container {
    margin-top: 8px;
    .dashboard-header__arrow {
      margin-top: -7px;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 585px) {
  .dashboard-header__name {
    margin: 0;
  }

  .dashboard-header-dropdown__link {
    width: 200px;
    float: right;

    & > .row {
      .columns {
        &.small-10 {
          width: 80%;
        }
        &.small-2 {
          width: 20%;
        }
      }
    }
  }

  .header--dashboard {
    .form.form--admin {
      margin-top: 10px;
    }
  }
}
