.learning-path {
  .header.header--article {
    background-color: #003366;
    border: 0;
  }

  .learning-content-hero.learning-content-hero--no-asset {
    height: 400px;
    background: black;
    overflow: hidden;
    background: #c33764;
    background: -webkit-linear-gradient(94.99deg, rgba(48, 207, 208, 0.8) 0%, rgba(51, 8, 103, 0.8) 100%),
      url(https://photoshop-kopona.com/uploads/posts/2018-03/1521289405_17-8.jpg);
    background: linear-gradient(94.99deg, rgba(48, 207, 208, 0.8) 0%, rgba(51, 8, 103, 0.8) 100%),
      url(https://photoshop-kopona.com/uploads/posts/2018-03/1521289405_17-8.jpg);
    background-size: cover;

    img {
      display: none;
    }

    .learning-content-hero__overlay {
      background: none !important;
    }
  }

  .hide-link {
    display: none !important;
  }

  .learning-path-header {
    display: flex;
    .dasboard-link-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 32px;
      a {
        color: #ffffff;
        font-size: 12px;
        font-family: Inter;
      }
    }
  }

  .global-nav__container > .nav {
    right: 32px;
  }

  .widget--learning-path-content_standard {
    .learning-path-body {
      .catalog-header {
        font-family: Inter;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        background-color: transparent;
        color: #333333;
      }
    }
  }

  .learning-path-milestone {
    background-color: transparent;
    .learning-path-milestone__title {
      margin-bottom: 10px;
    }
    .learning-path-milestone-item {
      background: #ffffff;
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      -webkit-box-flex: 0;
      flex: none;
      -webkit-box-ordinal-group: 1;
      order: 0;
      align-self: center;
      margin-top: 0px;
      padding: 35px 42px;
      margin-bottom: 30px;

      .learning-path-milestone-item__title > .row {
        display: flex;
        padding-bottom: 10px;

        & > .columns {
          display: flex;
          align-items: center;
        }

        .text-right {
          width: 100%;
        }
      }

      .learning-path-milestone-item__body {
      }

      .btn--primary.btn--small {
        background: rgba(1, 133, 229, 0.1);
        border-radius: 5px;
        color: #0185e5;
        padding: 15px 30px;
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
        border: none;
      }
    }

    .learning-path-milestone__badge {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      -webkit-box-align: center;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1em;

      border-radius: 3px;
      padding: 4px 9px;
      display: inline-block;
      &.learning-path-milestone__badge--required {
        border: 1px solid #ff595a;
        background-color: #ff595a;
      }

      &.learning-path-milestone__badge--elective {
        border: 1px solid #6fcf97;
        background-color: #6fcf97;
      }
    }
  }
}
