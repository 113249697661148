.widget--redemption-form {
  margin-top: 100px;
  max-width: 500px;
  margin: 100px auto;

  h5 {
    display: none;
  }

  .widget__title {
    text-align: left;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    font-weight: bold;
  }

  .medium-centered {
    margin: 0 !important;
    width: 100%;
  }

  .small-6.small-centered {
    width: 100%;
  }

  input[type='text'] {
    @include input-style;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 60px !important;
  }

  .btn.btn--primary.btn--submit.btn--floating-label.postfix {
    font-size: 12px !important;
    padding: 5px 15px !important;
    height: 60px !important;
    width: 120px !important;
  }

  .btn--link span {
    color: #b6b6b6 !important;
  }

  .terms__checkbox__wrapper {
    * {
      font-size: 14px;
      line-height: 16px;
      color: #333333 !important;
    }
    a,
    .btn--link {
      span {
        text-decoration: underline;
        color: #333333 !important;
      }
    }
  }

  .redeem-form-actions {
    display: flex;
    flex-direction: column;

    input[type='checkbox'] {
      @include checkbox-style;
    }
    & > .columns {
      float: none !important;
      width: 100%;
    }

    .btn.btn--primary {
      width: 305px;
      height: 54px;
      background: #0185e5 !important;
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
  }
}
