// Main Dashboard area. Contains the purchased courses and exams
.ember-view > .container {
  max-width: 100%;
  & > .header {
    background: #ffffff;
    color: #333333;
    border-bottom: 1px solid #7a7a7a;
  }
}

.container {
  background: #fff;
  box-shadow: none;
}

// TODO: Check with Asitha if this is a duplicate
.dashboard-access-tabs {
  // background: #003366; Ask asitha why we have 2
  background: #fff;
  border: none;
  margin-bottom: 10px;

  .dashboard-access-tab--inactive {
    background-color: #fff;
    .dashboard-access-tab__label {
      color: #015f9c;
    }
  }
}

.dashboard-access-tab--active {
  border-color: #003366 !important;
  .dashboard-access-tab__label {
    /*color:#015f9c;*/
    color: #fff;
  }
}

.dashboard-access-tab__count {
  color: #0099cc !important;
}
.icon-navigatedown,
.icon-navigateright {
  color: #0099cc !important;
}

.my-button {
  display: inline-block;
  margin: 55px; /* space between buttons */
  background: #0048a3; /* background color */
  color: #333; /* text color */
  font-size: 4em;
  font-family: 'sintony';
  font-style: normal;
  border-radius: 50px; /* rounded corners */
  padding: 8px 16px; /* space around text */
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.my-button:hover {
  background: #666;
  color: #c1e1e0;
}

.widget--dashboard-access.widget--dashboard-access_standard {
  .dashboard-access-list-item-expander__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    /* Text */

    color: #333333;
  }

  .dashboard-access {
    border: none;
  }

  .dashboard-access-tabs {
    margin-bottom: 70px;
  }

  .dashboard-access-tab--active {
    background-color: #fff;
    border-top: 0;
    border-bottom: 4px solid #003366;
    .dashboard-access-tab__label {
      color: #333;
    }
  }

  .dashboard-access-tab--inactive {
    .dashboard-access-tab__label {
      color: #ccc;
    }
  }

  .dashboard-access-list-item {
    @include card;
    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: center;
    margin: 0px;
    padding: 35px 42px;
    margin-bottom: 30px;

    .dashboard-access-list-item-expander {
      display: flex;
      flex-direction: column-reverse;
    }

    .col-span-4 {
      padding-top: 30px;
      min-height: 125px;
      i {
        min-width: 170px;
        margin-top: 5px;
        &.icon-navigateright::before {
          content: 'Show Progress \25BA';
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }

        &.icon-navigatedown::before {
          content: 'Hide Progress \25B2';
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }
      }
    }
    .medium-3 {
      strong {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #f2c94c;
        border: 1px solid #e9e9e9;
        border-radius: 3px;
        padding: 4px 9px;
        display: inline-block;
      }
    }

    .medium-2 {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: center;
      width: 25%;
      small {
        font-size: 14px;
        color: rgba(1, 133, 229, 0.6);
        text-align: center;
      }
      .btn {
        background: rgba(1, 133, 229, 0.1);
        border-radius: 5px;
        color: #0185e5;
        padding: 15px 30px;
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
        border: none;
      }
    }

    .user-engagement-stat.user-engagement-stat--percent-complete,
    .user-engagement-progress-bar {
      display: none;
    }
  }

  // styles related to the expansion items
  .dashboard-access-list-item-expansion {
    .medium-4 {
      display: none;
    }

    .user-engagement-stats {
      min-height: 70px;
      margin-bottom: 10px;

      &:before,
      &:after {
        display: none;
      }

      i {
        display: none;
      }

      li:before {
        display: none;
      }
    }

    .user-engagement-progress-bar {
      .progress {
        height: 0.5rem;
        width: 420px;
        padding: 0;
      }
    }

    .user-engagement-stat__label-container {
      position: absolute;
      top: 50px;
      width: 100%;
    }

    .user-engagement-stat__label {
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      /* Text */

      color: #333333;
      opacity: 0.3;
    }

    .user-engagement-stat__value {
      padding-left: 15px;
    }

    .user-engagement-stat__value--percent {
      margin-left: -10px;
      font-size: 20px;
      line-height: 40px;
    }

    .user-engagement-stat__value-container {
      margin-top: 0;
      * {
        font-weight: 300;
        font-size: 36px;
        line-height: 44px;
        color: #333333;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .learn.dashboard .widget,
  .learn.transcript .widget {
    padding: 20px 25px !important;
  }
}

@media only screen and (max-width: 768px) {
  .course-progress {
    .dashboard-access {
      .dashboard-access-list-item {
        position: relative;
        padding: 30px;
        .medium-3 {
          width: 90%;
        }
        .medium-2 {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 585px) {
  .learn.dashboard .widget,
  .learn.transcript .widget {
    padding: 10px !important;
  }

  .course-progress {
    .dashboard-access {
      .dashboard-access-list-item {
        position: relative;
        padding: 25px;
        .dashboard-access-list-item-expander__title {
          font-size: 18px;
        }
      }
    }

    .dashboard-access-tabs {
      margin-bottom: 30px;
    }

    .dashboard-access-tabs {
      display: flex;
      .dashboard-access-tab {
        padding: 10px 10px 10px 0;
        & > button {
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
        }
        .dashboard-access-tab__label {
          text-align: center;
        }
      }
    }
  }
}
