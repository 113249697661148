// @font-face {
//   font-family: SFProDisplay;
//   src: url('/src/assets/FontsFree-Net-SFProDisplay-Regular.ttf');
// }

// constants and mixins
@mixin card {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  // box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

@mixin input-style {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: none;
}

@mixin checkbox-style {
  width: 20px;
  &:after {
    font-family: 'ti-icons-rev2';
    content: ' ';
    background-color: #ffffff;
    border: 2px solid #333333;
    border-radius: 5px;
    color: #333333;
    width: 19px;
    height: 19px;
    display: block;
    position: absolute;
    top: 0;
    left: 3px;
    font-size: 14px;
    padding-left: 2px;
    cursor: pointer;
    align-items: center;
    display: flex;
  }
  &:checked:after {
    content: '\2714';
  }
}

// global styles
.container {
  background: #e5e5e5;
}

.btn {
  font-family: Inter;
}

// header and navigation
.header,
.header__inner {
  padding: 0;
}
// TODO: MOVE THIS TO THE GLOBAL SCSS
.header {
  .company__beta-logo {
    max-width: 300px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 25px;
  }
}

.global-nav__container {
  & > .nav {
    position: absolute;
    z-index: 10;
    right: 230px;
    top: 18px;
    a.active-link {
      text-decoration: underline;
    }
  }

  &.manager-view {
    & > .nav {
      top: 93px;
    }
  }
}

.nav--global-links {
  background-color: #ffffff;
  a {
    color: #333333;
    padding-left: 0;
    margin-left: 24px;

    &:hover {
      color: #0099cc;
    }
  }
}

// Input
.field__input-wrapper {
  .field__label {
    color: #b6b6b6;
  }
  ::placeholder {
    color: #b6b6b6 !important;
    // opacity: 1;
  }
}

.field--active .field__input-wrapper {
  ::placeholder {
    opacity: 0 !important;
  }
}

// iPad sizes
@media only screen and (max-width: 768px) {
  .global-nav__container > .nav {
    top: 0 !important;
    position: relative !important;
    right: 0 !important;
    padding-left: 20px;
  }

  .header.header--microsite {
    .header__inner {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .nav.nav--top {
        margin-top: 0;
      }
    }
  }
}

// mobile phones
@media only screen and (max-width: 585px) {
  .global-nav__container > .nav {
    padding-left: 0;
  }
  .header {
    &.header--microsite {
      .header__inner {
        flex-direction: row-reverse !important;
        justify-content: space-between !important;
        .company__beta-logo {
          width: 50%;
          padding: 0;
        }
      }
    }

    &.header--dashboard {
      .header__inner {
        & > .row {
          & > .column {
            padding: 0;
          }
        }
      }
    }

    .header__inner {
      flex-direction: column !important;
      align-items: flex-start !important;

      .avatar {
        height: 20px;
        width: 20px;
        &.avatar--initials {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .company__beta-logo {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      padding-left: 10px !important;
    }
    .header__title {
      position: relative !important;
      right: auto !important;
      top: auto !important;
      .h3 {
        text-align: left !important;
        padding-left: 25px !important;
      }
    }
  }

  .dashboard-header-dropdown {
    left: auto;
  }

  .nav--global-links {
    a {
      margin-left: 10px;
    }
  }
}
