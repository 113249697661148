.checkout__order {
  max-width: 90%;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 120px;
}

.checkout {
  .order__forms {
    padding-top: 30px;
    & > p {
      display: none;
    }
    .btn--huge:before {
      margin-right: 10px;
      content: '\E6A1';
      font-family: 'ti-icons-rev2';
    }
    .panel--secondary {
      background: #fff;
      border: none;
      padding: 0;
    }

    label[for='test-payment-terms'] {
      a span,
      .btn span {
        text-decoration: underline;
      }
    }
  }

  h4,
  .order__gift p.h4 {
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 29px !important;
    color: #333333;
  }

  input[type='text'],
  select,
  input[type='email'],
  input[type='tel'],
  textarea {
    @include input-style;
  }

  input {
    margin-bottom: 20px;
  }

  input,
  textarea {
    &::placeholder {
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      color: #b6b6b6;
    }
  }

  // change checkbox look and feel
  input[type='checkbox'] {
    @include checkbox-style;
  }

  .cart-item__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #333333;
    background: none !important;
  }

  .cart-item__total {
    &.cart-item__total--order {
      background: none !important;
    }
    span {
      font-weight: normal;
      font-size: 24px;
      line-height: 29px;
      text-align: right;
      color: #2f80ed;
      &.just-applied-coupon {
        color: #a1d683;
        background: none;
        padding: 0;
      }
    }
  }

  .cart-item__price {
    .has-tooltip {
      position: absolute;
      right: 0;
      top: 70%;

      .tooltip.coupon {
        border: 0;
        box-shadow: none;
        text-align: right;
        padding-right: 23px;
        .btn {
          height: 25px;
          width: 25px;
          i {
            font-size: 10px;
          }
        }
        div {
          font-size: 12px;
        }
      }
    }
    .btn {
      display: none;
    }
  }

  .order__label.order__label--total {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: #2f80ed;
  }

  .order__value.order__value--total {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    color: #2f80ed;
  }

  .order__total {
    border-color: #e9e9e9;

    .lf-tax-msg {
      margin-top: 5px;

      span {
        font-size: 12px;
        border: 1px solid;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  }

  .order__forms {
    p,
    .small-11 span {
      font-weight: normal;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #333333 !important;
    }

    .small-1 {
      width: 20px;
    }

    .small-11 {
      float: left;
      margin-left: 10px;
    }

    .payment-information {
      background: none;
      padding: 0;
    }

    .label--header span {
      font-weight: bold !important;
      font-size: 24px !important;
      line-height: 29px !important;
      color: #333333 !important;
    }
  }

  .btn--huge {
    background: #0185e5;
    border: 0;
    border-radius: 5px;
    width: 300px;
    margin-top: 40px;
    span {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #f2f2f2;
    }
  }

  .cart-item .coupon-entry .coupon-entry__label {
    position: absolute;
    width: 100%;
    top: 5px;
  }

  .cart-item__content {
    padding-bottom: 15px;
    .variation {
      padding: 0 1rem;
      * {
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        color: #b6b6b6;
      }
    }
  }

  // re-arranging the columns in the checkout page
  .order__index > .row {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    & > .large-6 {
      width: 50% !important;
    }
    .order__items__container {
      width: 70%;
      .large-6 {
        width: 100%;
      }
    }
  }
}

.cart-items {
  &.cart-items--order {
    @include card;
    padding: 27px 22px;
  }
}

.cart-item {
  .order__item__row--bottom {
    &.medium-8 {
      display: none;
    }
    &.right {
      float: left !important;
      & > .pull-right {
        float: left;
      }
      input {
        height: 25px;
        padding: 15px 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 15px 10px !important;
      }

      .btn--submit {
        font-size: 12px;
        height: 25px;
        padding: 15px 5px;
        line-height: 0;
      }

      label {
        display: none;
      }
    }
  }
}

.powered-by-stripe {
  margin-top: 20px;
  padding-left: 70px;
  img {
    width: 150px;
  }
}

.money-back {
  color: #0185e5;
  font-size: 12px;
  font-style: italic;
  padding-left: 64px;
}

.order__gift .medium-6 {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .cart-items {
    margin-top: 50px;
    position: relative;
    .continue-to-checkout {
      float: none;
      right: 0;
      position: absolute;
      bottom: -70px;
    }
  }

  .checkout .order__index > .row > .large-6 {
    width: 90% !important;
  }

  .checkout {
    .order__sidebar__container {
      top: 70px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .cart-items {
    .continue-to-checkout {
      width: 100%;
      left: 0;
    }

    .cart-item__price {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 15px;
      .btn {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }

  .checkout .order__index > .row > .large-6 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 585px) {
  .checkout {
    .order__sidebar__container {
      top: 76px;
    }
  }
}
