// This will affect ALL modals, not just the cart modal
// There is no way to target the cart specifically
.modal {
  .modal-dialog {
    .modal-content {
      @include card;
      padding: 40px;

      .modal-header {
        border-bottom: 2px solid #e9e9e9;

        button {
          color: #ffffff;
          padding: 0;
          &::after {
            content: 'Continue';
            display: block;
            font-size: 14px;
            line-height: 17px;
            color: #0099cc;
            float: right;
          }
        }
      }
      .modal-title {
        color: #333333;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
      }

      .modal-body {
        padding: 0;
        .cart-item {
          border-bottom: 2px solid #e9e9e9;
          padding: 20px 0;
          img {
            display: none;
          }

          .cart-item__text {
            margin-left: 0;
            .cart-item__title {
              font-weight: bold;
              font-size: 22px;
              line-height: 26px;
              margin-bottom: 15px;
            }
            p span {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 17px;
              color: #b6b6b6;
            }
          }
        }

        .cart__total {
          border-top: 0;
          background-color: transparent;
          padding-top: 40px;
          display: flex;
          justify-content: space-between;
          padding-right: 0;
          color: #4f4f4f;
          .cart__total__label {
            font-weight: normal;
            color: #4f4f4f;
            font-size: 22px;
          }
        }

        .cart__buttons {
          .btn {
            &.btn--primary {
              background: #0185e5;
              box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
              border-radius: 5px;
              display: flex;
              align-items: center;
              &::before {
                content: ' ';
                display: inline-block;
                height: 15px;
                width: 15px;
                background: url('/assets/icons/lock.svg') no-repeat;
                background-size: cover;
                margin-right: 10px;
              }
            }
            &.btn--alt {
              display: none;
            }
          }
        }
      }
    }
  }
}
