footer {
  background: #003366;
  color: #fff;
  * {
    color: #fff !important;
  }
  a:hover {
    color: #ccc !important;
  }
}

.footer__inner {
  border-top: 0;
  padding: 2rem 0 1rem;
}
