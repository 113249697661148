.header--checkout {
  .header__title {
    position: absolute;
    right: 25px;
    top: 20px;
    h2.h3 {
      font-size: 15px;
      line-height: 19px;
      text-align: right;
      color: #a1d683;

      &:before {
        margin-right: 10px;
        content: '\E6A1';
        font-family: 'ti-icons-rev2';
      }
    }
  }
}
