.session {
  padding: 0;
  padding-bottom: 150px;
  position: relative;
  .header.header--order-confirmation {
    background: #003366;
    color: #fff;
    margin-bottom: 50px;
    .header__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 25px;
      .header__title h2 {
        font-size: 15px;
        line-height: 19px;
        text-align: right;
        color: #a1d683;
      }
      &::after {
        display: none;
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .session__container {
    @include card;
    padding: 50px 30px;
  }

  .company__beta-logo {
    width: 420px;
  }

  .order-confirmation {
    .h3.heading {
      padding-top: 50px;
      font-weight: normal;
      font-size: 16px;
      text-align: center;
      margin-bottom: 0;
    }
    & > p:first-of-type {
      padding: 0 50px 50px 50px;
      text-align: center;
    }

    .cart-items {
      .cart-item {
        @include card;
        box-shadow: none;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;

        .receipt-item__asset {
          display: none;
        }
        .receipt-item__content {
          flex: 1;
          padding: 0;
          display: flex;
          .receipt-item__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
            flex: 1;
            .receipt-item__title {
              font-size: 12px;
              font-weight: bold;
              flex-basis: 80%;
              margin-bottom: 10px;
            }
            & > p {
              font-size: 10px;
              padding-right: 30px;
            }
          }
          & > p {
            flex-basis: 30%;
            padding-left: 15px;
            font-size: 10px;
            strong {
              font-size: 12px;
              font-weight: normal;
              margin-bottom: 0;
              &:before {
                content: 'UNIT PRICE';
                display: block;
                font-weight: bold;
                color: #7a7a7a;
              }
            }
          }
        }
        .total-value {
          font-size: 12px;
          font-weight: normal;
          flex-basis: 15%;
          margin-bottom: 0;
          margin-right: 20px;
          padding-left: 20px;
          &:before {
            content: 'TOTAL';
            display: block;
            font-weight: bold;
            color: #7a7a7a;
          }
        }
        .btn {
          border-radius: 5px;
          flex-basis: 30%;
          margin-bottom: 0;
        }
      }
    }

    .total-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 10px;
      font-size: 12px;
    }

    .extra-container {
      margin-top: 20px;
      padding: 0 30px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .session {
    .session__container.medium-6.medium-push-3 {
      width: 90% !important;
      left: 0 !important;
      margin: 0 auto;
      box-sizing: border-box;
      float: none;
    }

    .company__beta-logo {
      width: 90%;
    }

    .cart-item {
      flex-direction: column !important;
      align-items: stretch !important;
    }
  }
}
