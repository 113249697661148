.catalog-display-type i {
  color: #0099cc;
}
.catalog-aggregation--expanded {
  background-color: transparent;
  border-bottom: 1px solid #0099cc;
}
.catalog-aggregation {
  border-top: 0px solid #cbcbcb;
}
.catalog-grid-item__title {
  color: #807f83;
  text-align: center;
}
.catalog-grid-item {
  border: 1px solid #807f83;
  border-radius: 0.85rem !important;
}
.catalog-grid-item img {
  border-top-left-radius: 0.85rem !important;
  border-top-right-radius: 0.85rem !important;
}
.catalog-grid-item__source {
  color: #003366;
  text-align: center;
}
.catalog-grid-item__hr {
  border-color: #0099cc;
}
.catalog-header {
  color: #fff;
  background: #003366;
  border-radius: 25px;
  padding: 15px;
  font-size: 15px;
}

.catalog-grid-item__body {
  // height: 150px;
  // min-height: 150px;
  // max-height: 200px;
  .catalog-grid-item__cta-container {
    height: 20px !important;
    // Targetting the button container because TI has added 2 invisible rows that contains nothing
    &>.grid.grid-cols-3.gap-2 {
      .place-self-center {
        place-self: auto !important;
      }
    }
  }
}
