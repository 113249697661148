.course__container {
  .learner__container--closed {
    position: relative;
    .learner__container__toggle {
      position: absolute;
      left: 0;
    }
  }

  .learner__container--toggled {
    .learner__content {
      .learner__container__toggle {
        display: none;
      }
    }
  }

  .header--student {
    box-shadow: none;
    height: 64px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row-reverse;
    border-bottom: 1px solid #7a7a7a;
    .header__left__icon {
      background-color: #7a7a7a;
      color: #fff;
      font-size: 14px;
      float: left;
      height: 40px;
      width: 40px;
      margin: 0;
      padding: 0;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #fff;
      margin-top: 19px;
      margin-left: 11px;
      transition: background-color 0.2s ease;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header__left {
      display: flex;
      flex: 1;
      float: none;
      left: auto;
      right: auto;
      align-items: center;
      padding: 0 15px;
      justify-content: space-between;
      a {
        margin: 0;
      }
      .header__left__copy {
        display: flex;
        padding: 0 !important;
        align-items: center;
        .course-title {
          color: #333333;
          font-size: 22px;
          margin-left: 10px;
          width: 100%;
        }
      }
      .expandable-sidebar__buttons {
        background-color: transparent;
        min-width: 155px;
        a {
          color: #333333;
          &:hover {
            color: #0099cc;
          }
        }
      }
    }
    .header__right {
      display: none;
    }
  }
}

.header__left__copy {
  padding-top: 25px !important;
  .course-title {
    color: #003366;
    text-transform: uppercase;
    font-size: 16px;
    /*text-align: right;*/
  }
}

@media only screen and (max-width: 768px) {
  .course__container {
    .learner__container {
      padding-top: 90px;
    }
    .header--student {
      .header__left {
        justify-content: flex-start;
        .header__left__copy {
          flex: 1;
          margin-left: 10px;
        }
        .course-title {
          font-size: 16px !important;
          text-transform: none !important;
          text-align: left !important;
        }
      }
      .header__left__icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 585px) {
  .course__container {
    .header--student {
      height: 75px;
      .header__left {
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-end;
        .header__left__copy {
          flex-basis: 90%;
          flex: auto;
          height: auto;
        }
        .course-title {
          font-size: 12px !important;
        }
        .expandable-sidebar__buttons {
          margin-top: 10px;
        }
      }
      .header__left__icon {
        width: 30px;
        height: 28px;
      }
    }
  }
}
