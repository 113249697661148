.banner-ad-slider {
  .featured-content-carousel-item__overlay {
    background: transparent !important;
  }

  &.single-image {
    .featured-content-carousel__dots,
    .featured-content-carousel__nav {
      display: none !important;
    }
  }
}
