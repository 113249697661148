@mixin column-header {
  display: block;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #7a7a7a;
}

.learn.account {
  .header--subtitle {
    display: none;
  }

  .dashboard-account {
    padding-top: 120px;

    .dashboard-account-tabs.unlist {
      li.dashboard-account-tab {
        background-color: #ffffff;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 28px 20px;
        margin-bottom: 10px;
        cursor: pointer;
        .dashboard-account-tab__label {
          color: #333333;
          font-size: 18px;
          font-weight: bold;
        }
        &.dashboard-account-tab--active {
          background-color: #0185e5;
          .dashboard-account-tab__label {
            color: #ffffff;
          }
        }
        &.hide-tab {
          display: none;
        }
      }
    }

    // Account Info section
    #account-section-1 {
      .btn {
        display: none;
        margin: 0 auto;
        padding: 16px 30px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        width: 305px;
        height: 54px;
        background: #0099cc;
        /* Nav Dropshadow */

        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
      }
    }

    // Credit Card
    #account-section-2 {
      .section-container {
        box-shadow: none;
        width: 600px;
        margin: 0 auto;
      }
      .section__header {
        background-color: transparent;
        border: 0;
        padding: 0;
        margin-bottom: 50px;
        & > .row > .columns {
          padding: 0;
        }
        .section__title {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: #333333;
          text-transform: none;
        }
      }
      .section__body {
        border: 0;
        padding: 0;
        h4 {
          display: none;
        }
        & > p {
          font-weight: bold;
        }
        form {
          margin-top: 50px;
          .btn {
            width: 297px;
            height: 54px;
            border: 0;
            margin-top: 15px;
            background: #0099cc;
            border-radius: 5px;
            font-size: 18px;
            font-weight: bold;
            &::after {
              content: ' Credit Card';
            }
          }
        }
      }
    }

    // Purchases
    #account-section-5 {
      .section-container {
        box-shadow: none;
      }
      .section__header {
        background-color: transparent;
        border: 0;
        padding: 0;
        margin-bottom: 50px;
        .section__title {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          margin: 12px 0;
        }
      }
      .section__body {
        .large-offset-6.large-6.columns {
          position: absolute;
          top: 0;
          right: 0;
          margin-left: auto !important;
        }
        select {
          @include input-style;
          height: 53px;
          width: 80%;
        }
        .btn.btn--large {
          width: 223px;
          padding: 15px 0;
          background: #0099cc;
          box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
          border-radius: 5px;
        }

        .purchase-list__container {
          .purchase-list__header {
            display: none;
          }
          .purchase-item {
            @include card;
            margin-bottom: 10px;
            padding: 20px 0px;

            .purchase-item-info {
              font-size: 16px;
            }

            .purchase-item__label {
              font-weight: bold;
            }

            .purchase-item-info:nth-child(2) {
              width: 35%;
            }
            .purchase-item-info:nth-child(3) {
              width: 25%;
              &::before {
                content: 'TRANSACTION TYPE';
                @include column-header;
              }
            }

            .purchase-item-info:nth-child(4) {
              width: 15%;
              &::before {
                content: 'CHARGE';
                @include column-header;
              }
            }
            .purchase-item-info:nth-child(5) {
              width: 15%;
              &::before {
                content: 'CHARGE DATE';
                @include column-header;
              }
            }
          }
        }

        // totals
        .text-centered {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #333333;
          padding-top: 6px;
        }

        #purchases-pagination-next,
        #purchases-pagination-before {
          button {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #0099cc;
          }
        }
      }
    }
  }
}
